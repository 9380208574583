import { AnchorButton } from '../Button';
import { Card } from 'src/components/Blog';
import React from 'react';
import { Section } from '../Section';
import { SectionHeaderBlock } from 'src/components/Section/elements';
import { SectionTitle } from 'src/components/Typography';
import colors from 'src/style/colors';
import { mediaQueries } from 'src/style/breakpoints';

const LatestsPosts = React.memo(({ data, theme }) => {
  return (
    <Section theme={theme ? theme : 'light'}>
      <div
        css={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <SectionHeaderBlock>
          <SectionTitle isMobileCenter>
            Consultez nos derniers<span css={{ color: colors.purple }}>&nbsp;articles</span>
          </SectionTitle>
        </SectionHeaderBlock>
      </div>
      <div
        css={{
          display: 'flex',
          flexDirection: 'row',
          flex: 1,
          flexWrap: 'wrap',
          [mediaQueries.phoneOnly]: {
            marginTop: 30,
          },
        }}
      >
        {data.map((edge) => (
          <Card key={edge.node.id} post={edge.node} />
        ))}
      </div>
      <div css={{ display: 'flex', justifyContent: 'center', flex: 1, margin: '50px 0 30px' }}>
        <AnchorButton to="/blog">Plus d'articles</AnchorButton>
      </div>
    </Section>
  );
});

export default LatestsPosts;
