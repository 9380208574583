// Gatsby Image
import { GatsbyImage } from 'gatsby-plugin-image';
// Components
import { Paragraph } from 'src/components/Typography';
import React from 'react';
// Styles
import colors from 'src/style/colors';
import { mediaQueries } from 'src/style/breakpoints';

const AlumniCard = ({ data }) => {
  return (
    <a
      href={data.link}
      target="_blank"
      rel="noopener noreferrer"
      css={{
        cursor: 'pointer',
        textDecoration: 'none',
        backgroundColor: '#FFF',
        boxShadow: '0 5px 15px rgba(0,0,0,.07)',
        transitionProperty: 'all',
        transitionDuration: '0.2s',
        display: 'flex',
        flexDirection: 'row',
        borderRadius: 5,
        padding: 15,
        marginLeft: 10,
        marginRight: 10,
        marginBottom: 20,
        width: '100%',
        maxWidth: 275,
        '&:hover': {
          boxShadow: '0 10px 15px rgba(0,0,0,.09)',
          transform: 'translateY(-5px)',
        },
        // phone only
        [`@media screen and (max-width: 669px)`]: {
          marginLeft: 0,
          marginRight: 20,
          width: '100%',
          maxWidth: 'none',
          justifyContent: 'space-around',
        },
        // tabletLandscapeUp and desktopUp
        [`@media screen and (min-width: 900px)`]: {
          marginLeft: 0,
          marginRight: 20,
          width: `calc((100% - 80px) / 3)`,
          '&:nth-of-type(2n)': {
            marginRight: 20,
          },
          '&:nth-of-type(3n)': {
            marginRight: 0,
          },
        },
        // mediumDesktopUp and bigDesktopUp
        [`@media screen and (min-width: 1350px)`]: {
          maxWidth: 270,
          '&:nth-of-type(3n)': {
            marginRight: 20,
          },
          '&:nth-of-type(4n)': {
            marginRight: 0,
          },
          '&:last-of-type': { marginRight: 0 },
        },
      }}
    >
      {/* COLUMN LEFT */}
      <div
        css={{
          height: 80,
          width: 80,
          marginRight: 10,
        }}
      >
        <GatsbyImage
          image={data.avatar}
          css={{
            height: '100%',
            borderRadius: 5,
            width: '100%',
          }}
          alt={`photographie de ${data.name}, ancien élève du Reacteur`}
        />
      </div>
      {/* COLUMN RIGHT */}
      <div
        css={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-end',
        }}
      >
        <Paragraph
          css={{
            color: colors.purple,
            padding: 0,
            marginTop: 5,
            fontWeight: 'bold',
            fontSize: 16,
            lineHeight: '18px',
            marginBottom: 'auto',
          }}
        >
          {data.company ? data.company : 'Indépendant'}
        </Paragraph>

        <Paragraph
          css={{
            color: colors.purpleDark,
            padding: 0,
            marginTop: 5,
            fontWeight: 'bold',
            fontSize: 16,
            lineHeight: '20px',
          }}
        >
          {data.name}
        </Paragraph>
        <Paragraph
          css={{
            textAlign: 'start',
            padding: 0,
            fontSize: 13,
            lineHeight: '16px',
            [mediaQueries.phoneOnly]: {
              fontSize: 13,
              lineHeight: '16px',
            },
          }}
        >
          {data.title}
        </Paragraph>
        {/* </div> */}
      </div>
    </a>
  );
};

export default AlumniCard;
