import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

/* --- import Styles ----*/
import { Section, TwoColumns } from '../Section';
import { TitleDescription } from '../Typography';
import colors from 'src/style/colors';

/* --- import Components ----*/
import { SectionHeaderBlock } from 'src/components/Section/elements';
import { SectionTitle, Paragraph } from 'src/components/Typography';
import { GalaxyIllustration } from 'src/components/Bootcamp/Schedule/Program/index';
import { GalaxyIllustrationWrapper } from 'src/components/Bootcamp/Schedule/Program/elements';
import sizes from 'src/style/sizes';

/* --- roughNotation --- */
// import Sensor from '../Sensor';
// import RoughNotation from '../Notation';
// import { RoughNotationGroup } from 'react-rough-notation';

/* --- import Media Queries ----*/
import { mediaQueries } from 'src/style/breakpoints';

const TASection = React.memo(({ training }) => {
  return (
    <div
      css={{
        paddingTop: 20,
        position: 'relative',
        [mediaQueries.tabletPortraitUp]: {
          paddingTop: 20,
        },
        [mediaQueries.tabletLandscapeUp]: {
          paddingTop: 0,
        },
      }}
    >
      <div
        css={{
          position: 'relative',
          backgroundColor: 'blue',
        }}
      >
        <GalaxyIllustrationWrapper>
          <GalaxyIllustration />
        </GalaxyIllustrationWrapper>
      </div>

      <Section
        theme="primary"
        css={{
          paddingBottom: 184,
          [mediaQueries.desktopUp]: {
            paddingBottom: 176,
          },
          [mediaQueries.phoneOnly]: {
            paddingBottom: 96,
          },
        }}
      >
        <div
          css={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <SectionHeaderBlock css={{ marginBottom: 72, paddingBottom: 0 }}>
            {training === 'eLearning' ? (
              <SectionTitle isMobileCenter isCenter>
                Nos formateurs vous aident lors de rendez-vous individuels pour
                <span css={{ color: colors.yellow }}>&nbsp;progresser</span>
              </SectionTitle>
            ) : (
              <SectionTitle isMobileCenter isCenter>
                Nos formateurs vous aident toute la journée pour
                <span css={{ color: colors.yellow }}>&nbsp;progresser</span>
              </SectionTitle>
            )}
          </SectionHeaderBlock>
        </div>
        <TwoColumns
          center
          reverse
          sizeLeft={sizes.columnWidth.medium}
          left={
            // <Sensor>
            //   {({ isVisible }) => (
            <div>
              <TitleDescription>
                Si vous rencontrez une difficulté, nos mentors sont à vos côtés !{' '}
              </TitleDescription>

              <Paragraph>
                Au Reacteur, notre priorité est d’aider nos étudiants à progresser le plus
                rapidement possible. C’est pourquoi vous serez accompagnés par nos 4 formateurs :
                Tom, Lucas, Gwendoline et Alexis, dans la mise en pratique des notions apprises,
                pour s’assurer de leur bonne compréhension.
                <br />
                <br />
                {/* <RoughNotationGroup show={isVisible} animate={true} animationDelay={500}> */}
                Ces échanges, instructifs et bienveillants, sont essentiels lors de la formation.
                Ils permettent aux élèves d’évoluer rapidement et de gagner en autonomie. À noter :
                au Reacteur, les étudiants bénéficient d’un accompagnement individuel{' '}
                {training === 'eLearning' || training === 'tps-partiel' ? 'en visio' : ''}{' '}
                (one-to-one) et pas seulement via un chat.
              </Paragraph>
            </div>
            //   )}
            // </Sensor>
          }
          sizeRight={sizes.columnWidth.medium}
          right={
            <div
              css={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <StaticImage
                src="../../../src/data/images/2023_teachers.png"
                alt="Formation Développeur Web et Mobile : formateurs"
                // placeholder="none"
                css={{
                  alignItems: 'center',
                  justifyContent: 'center',
                  backgroundColor: '#FFFFFF',
                  borderRadius: 5,
                  [mediaQueries.phoneOnly]: {
                    maxHeight: 450,
                    maxWidth: 594,
                  },
                  [mediaQueries.tabletPortraitUp]: {
                    maxHeight: 450,
                    maxWidth: 594,
                  },
                  [mediaQueries.tabletLandscapeUp]: {
                    maxHeight: 371,
                    maxWidth: 490,
                  },
                }}
              />
            </div>
          }
        />
      </Section>
    </div>
  );
});
export default TASection;
