import React, { useState } from 'react';

//Components
import { Section, TwoColumns } from '../../Section';
import { SectionHeaderBlock } from 'src/components/Section/elements';
import VideosTestimonials from './VideosTestimonials';
import MainVideoTestimonial from './MainVideoTestimonial';
import { useMediaQueries } from '../../../utils/MediaQueries';

// Styles
import { SectionTitle, Paragraph } from 'src/components/Typography';
import sizes from 'src/style/sizes';
import colors from 'src/style/colors';

// Data
import Students from 'src/data/Students';
import { Button } from 'src/components/Button';

const Testimonials = React.memo(({ theme }) => {
  const data = Students();
  const mqs = useMediaQueries();

  // phone display
  const [isExpanded, setIsExpanded] = useState(false);
  const visibleTestimony = isExpanded ? 5 : 2;

  return (
    <Section theme={theme ? theme : 'light'}>
      <div
        css={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <SectionHeaderBlock>
          <SectionTitle isMobileCenter>
            Ils nous ont fait<span css={{ color: colors.purple }}>&nbsp;confiance</span>
          </SectionTitle>
        </SectionHeaderBlock>
      </div>

      {mqs.phoneOnly ? (
        <>
          <Paragraph>
            Nos élèves viennent de tous les milieux. Issus de cursus différents, parfois avec des
            métiers très éloignés du monde de la programmation, ils se sont tous donnés les moyens
            de changer de vie et donc d'univers professionnel. Pourquoi pas vous ?
          </Paragraph>
          <div
            css={{
              position: 'relative',
            }}
          >
            {data.slice(0, visibleTestimony).map((testimony) => {
              return (
                <MainVideoTestimonial
                  mainThumbnail={testimony.thumbnail}
                  link={testimony.link}
                  key={testimony.title}
                />
              );
            })}
            {!isExpanded && (
              <div
                css={{
                  position: 'absolute',
                  left: '-20px',
                  right: '-20px',
                  bottom: '0px',
                  height: '50%',
                  background:
                    'linear-gradient(0deg, rgba(250,250,252,5) 20%, rgba(250,250,252,.2) 100%)',
                }}
              ></div>
            )}
          </div>

          <div
            css={{ display: 'flex', justifyContent: 'center', paddingTop: 30, paddingBottom: 20 }}
          >
            <Button
              onClick={() => setIsExpanded(!isExpanded)}
              css={{
                textDecoration: 'none',
                color: '#FFF',
              }}
            >
              {isExpanded ? 'Voir moins' : `Voir plus de témoignages`}
            </Button>
          </div>
        </>
      ) : (
        <TwoColumns
          sizeLeft={sizes.columnWidth.small}
          left={
            <>
              <Paragraph>
                Nos élèves viennent de tous les milieux. Issus de cursus différents, parfois avec
                des métiers très éloignés du monde de la programmation, ils se sont tous donnés les
                moyens de changer de vie et donc d'univers professionnel. Pourquoi pas vous ?
              </Paragraph>
              <MainVideoTestimonial mainThumbnail={data[0].thumbnail} link={data[0].link} />
            </>
          }
          sizeRight={sizes.columnWidth.large}
          right={<VideosTestimonials data={data} css={{ position: 'relative' }} />}
        />
      )}
    </Section>
  );
});

export default Testimonials;
