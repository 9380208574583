import React from 'react';

// Gatsby Image
import { GatsbyImage } from 'gatsby-plugin-image';

//Components
import Star from '../Icons/Star';

// Styles
import { Paragraph, StyledLinkA } from '../Typography';
import colors from 'src/style/colors';
import { mediaQueries } from 'src/style/breakpoints';

const KeyPointsStatic = ({ source, imgData, rate, landing }) => {
  return (
    <div
      css={{
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        width: landing ? '80%' : '100%',
        marginBottom: 50,
        [mediaQueries.tabletLandscapeUp]: {
          alignItems: 'center',
          flexDirection: 'column',
          marginBottom: 0,
        },
        [mediaQueries.desktopUp]: {
          flexDirection: 'row',
        },
      }}
    >
      <div
        css={{
          display: 'flex',
          width: '100%',
          height: '100%',
          justifyContent: 'center',
          margin: '0 0 18px 0',
          [mediaQueries.tabletLandscapeUp]: {
            width: '90%',
          },
          [mediaQueries.desktopUp]: {
            width: '60%',
            margin: '0',
          },
        }}
      >
        <GatsbyImage
          image={imgData}
          alt="Google"
          css={{
            alignItems: 'center',
            justifyContent: 'center',
            width: '35%',
            maxWidth: 135,
            [mediaQueries.phoneOnly]: {
              height: '70%',
            },
            [mediaQueries.tabletLandscapeUp]: {
              height: '70%',
            },
            [mediaQueries.desktopUp]: {
              width: '100%',
            },
          }}
        />
      </div>

      <div
        css={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          [mediaQueries.tabletLandscapeUp]: {
            width: '100%',
            alignItems: 'center',
          },
          [mediaQueries.desktopUp]: {
            alignItems: 'flex-start',
            paddingLeft: landing ? 10 : 25,
          },
        }}
      >
        <Paragraph
          css={{ fontWeight: 'bold', paddingTop: 0, paddingBottom: 0, lineHeight: '22px' }}
        >
          <span css={{ color: landing ? colors.yellow : colors.purple, fontSize: 30 }}>{rate}</span>
          /5
        </Paragraph>
        <Paragraph
          css={{ fontWeight: 'bold', paddingTop: 0, paddingBottom: 15, lineHeight: '22px' }}
        >
          sur {source}
        </Paragraph>

        <div
          css={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <div
            css={{
              marginRight: 10,
              '&>svg': {
                marginRight: 2,
              },
            }}
          >
            <Star width="15" height="16" color={rate >= 1 ? '' : colors.midGrey} />
            <Star width="15" height="16" color={rate >= 1.5 ? '' : colors.midGrey} />
            <Star width="15" height="16" color={rate >= 2.5 ? '' : colors.midGrey} />
            <Star width="15" height="16" color={rate >= 3.5 ? '' : colors.midGrey} />
            <Star width="15" height="16" color={rate >= 4.5 ? '' : colors.midGrey} />
          </div>

          {source === 'SwitchUp' && (
            <StyledLinkA
              css={{
                fontSize: 15,
                lineHeight: '18px',
              }}
              color={colors.purple}
              href="https://www.switchup.org/bootcamps/le-reacteur"
              rel="noopener noreferrer"
              target="_blank"
            >
              88+ avis
            </StyledLinkA>
          )}
          {source === 'Course Report' && (
            <StyledLinkA
              css={{
                fontSize: 15,
                lineHeight: '18px',
              }}
              color={colors.purple}
              href="https://www.coursereport.com/schools/le-reacteur#reviews"
              rel="noopener noreferrer"
              target="_blank"
            >
              80+ avis
            </StyledLinkA>
          )}
          {source === 'Google' && !landing && (
            <StyledLinkA
              css={{
                fontSize: 15,
                lineHeight: '18px',
              }}
              color={colors.purple}
              href="https://www.google.com/search?q=le+reacteur&rlz=1C5CHFA_enFR868FR870&oq=le+reacteur&aqs=chrome.0.69i59l2j0i395l2j69i60l3j69i65.5077j1j7&sourceid=chrome&ie=UTF-8#lrd=0x47e66d87bdd508bd:0x30c72c8479c40156,1"
              rel="noopener noreferrer"
              target="_blank"
            >
              235+ avis
            </StyledLinkA>
          )}
        </div>
      </div>
    </div>
  );
};

export default KeyPointsStatic;
