import { useEffect, useState } from 'react';

import React from 'react';
import { Section } from '../Section';
import axios from 'axios';
import { mediaQueries } from 'src/style/breakpoints';
import colors from '../../style/colors';
import { SectionTitle } from '../Typography';
import { SectionHeaderBlock } from '../Section/elements';

const InstagramFeed = React.memo(({ theme }) => {
  const [instaDisplay, setInstaDisplay] = useState(false);
  const [instaItems, setInstaItems] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(`${process.env.GATSBY_BASE_URL_NEW_API}/instagram`);
        if (res.status > 200 || !res.data.data.length) {
        } else {
          setInstaItems(res.data.data);
          setInstaDisplay(true);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);

  const instaFeedStyle = {
    position: 'relative',
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    '&:hover': {
      filter: 'brightness(40%)',
    },
  };

  return (
    <>
      {instaDisplay && instaItems ? (
        <Section theme={theme ? theme : 'light'}>
          <div
            css={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <SectionHeaderBlock>
              <SectionTitle isMobileCenter>
                <a
                  href="https://www.instagram.com/lereacteur.io/"
                  target="_blank"
                  rel="noreferrer"
                  css={{
                    textDecoration: 'none',
                    color: colors.purpleDark,
                    justifyContent: 'center',
                    textAlign: 'center',
                  }}
                >
                  <span css={{ color: colors.purple }}>Suivez-nous&nbsp;</span>
                  sur Instagram
                </a>
              </SectionTitle>
            </SectionHeaderBlock>
          </div>

          <div
            css={{
              marginTop: '10px',
              display: 'flex',
              gap: '2px',
              overflow: 'hidden',
              flexWrap: 'nowrap',
              overflowX: 'scroll',
              '&::-webkit-scrollbar': { display: 'none' },
              [mediaQueries.mediumDesktopUp]: {
                justifyContent: 'center',
              },
            }}
          >
            {instaItems.map((item) => {
              return (
                <div
                  key={item.id}
                  style={{
                    width: '200px',
                    height: '200px',
                    flexShrink: 0,
                  }}
                >
                  <a
                    href={item.permalink}
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="instagram"
                  >
                    <div css={instaFeedStyle}>
                      {item.media_type === 'VIDEO' ? (
                        <svg
                          aria-label="Clip"
                          className="_ab6-"
                          color="#ffffff"
                          fill="#ffffff"
                          height="18"
                          role="img"
                          viewBox="0 0 24 24"
                          width="18"
                          style={{
                            position: 'absolute',
                            top: '10px',
                            right: '10px',
                            zIndex: 10,
                          }}
                        >
                          <path
                            d="M12.823 1l2.974 5.002h-5.58l-2.65-4.971c.206-.013.419-.022.642-.027L8.55 1zm2.327 0h.298c3.06 0 4.468.754 5.64 1.887a6.007 6.007 0 011.596 2.82l.07.295h-4.629L15.15 1zm-9.667.377L7.95 6.002H1.244a6.01 6.01 0 013.942-4.53zm9.735 12.834l-4.545-2.624a.909.909 0 00-1.356.668l-.008.12v5.248a.91.91 0 001.255.84l.109-.053 4.545-2.624a.909.909 0 00.1-1.507l-.1-.068-4.545-2.624zm-14.2-6.209h21.964l.015.36.003.189v6.899c0 3.061-.755 4.469-1.888 5.64-1.151 1.114-2.5 1.856-5.33 1.909l-.334.003H8.551c-3.06 0-4.467-.755-5.64-1.889-1.114-1.15-1.854-2.498-1.908-5.33L1 15.45V8.551l.003-.189z"
                            fillRule="evenodd"
                          ></path>
                        </svg>
                      ) : (
                        <svg
                          aria-label="Carousel"
                          color="#ffffff"
                          fill="#ffffff"
                          height="22"
                          role="img"
                          viewBox="0 0 48 48"
                          width="22"
                          style={{
                            position: 'absolute',
                            top: '10px',
                            right: '10px',
                            zIndex: 10,
                          }}
                        >
                          <path d="M34.8 29.7V11c0-2.9-2.3-5.2-5.2-5.2H11c-2.9 0-5.2 2.3-5.2 5.2v18.7c0 2.9 2.3 5.2 5.2 5.2h18.7c2.8-.1 5.1-2.4 5.1-5.2zM39.2 15v16.1c0 4.5-3.7 8.2-8.2 8.2H14.9c-.6 0-.9.7-.5 1.1 1 1.1 2.4 1.8 4.1 1.8h13.4c5.7 0 10.3-4.6 10.3-10.3V18.5c0-1.6-.7-3.1-1.8-4.1-.5-.4-1.2 0-1.2.6z"></path>
                        </svg>
                      )}
                      {item.media_type === 'VIDEO' ? (
                        <video
                          src={item.media_url}
                          alt="instagram post"
                          style={{
                            objectFit: 'cover',
                            width: '100%',
                            height: '100%',
                          }}
                        />
                      ) : (
                        <img
                          src={item.media_url}
                          alt="instagram post"
                          style={{
                            objectFit: 'cover',
                            width: '100%',
                            height: '100%',
                          }}
                        />
                      )}
                    </div>
                  </a>
                </div>
              );
            })}
          </div>
        </Section>
      ) : null}
    </>
  );
});

export default InstagramFeed;
