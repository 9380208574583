import React, { useState } from 'react';
import { PlayButton } from '../../../components/Button';
import ModalVideo from '../../../components/Modal/ModalVideo';
import BackgroundImage from 'gatsby-background-image';
import { mediaQueries } from 'src/style/breakpoints';

/**
 * @type React.FunctionComponent<{ mainThumbnail: string}>
 */

const SecondaryVideo = React.memo(({ className, link, mainThumbnail }) => {
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <div
      css={{
        flex: 1,
        objectFit: 'contain',
        aspectRatio: '1.77',
        width: '100%',
        // height: '100%',
        transitionProperty: 'all',
        transitionDuration: '0.3s',
        transitionTimingFunction: 'cubic-bezier(0.41, 0.094, 0.54, 0.07)',
        transitionDelay: '0s',
        [mediaQueries.desktopUp]: {
          boxShadow: 'rgba(0, 0, 0, 0.07) 0px 4px 12px',
          '&:hover': { boxShadow: 'rgba(0, 0, 0, 0.1) 0px 8px 16px' },
        },
      }}
    >
      <BackgroundImage
        onClick={() => {
          setModalOpen(!modalOpen);
        }}
        fluid={mainThumbnail}
        fadeIn={false}
        alt="Le Reacteur est la meilleure formation de code Web et Mobile à Paris "
        Tag="div"
        className={className}
        css={{
          borderRadius: 5,
          WebkitMaskImage: '-webkit-radial-gradient(white, black)',
          objectFit: 'cover',
          overflow: 'hidden',
          cursor: 'pointer',
          display: 'flex',
          width: '100%',
          height: '100%',
        }}
      >
        <div
          css={{
            '&:before': {
              backgroundImage: 'linear-gradient(to top, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0))',
              position: 'absolute',
              content: '""',
              display: 'block',
              top: 0,
              bottom: 0,
              right: 0,
              left: 0,
              width: '100%',
              height: '100%',
              opacity: 0.8,
              transitionProperty: 'all',
              transitionDuration: '0.3s',
              transitionTimingFunction: 'cubic-bezier(0.41, 0.094, 0.54, 0.07)',
              transitionDelay: '0s',
            },
            '&:hover:before': {
              opacity: 1,
            },
          }}
        >
          <div
            css={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              opacity: 0.8,
            }}
          >
            <PlayButton
              size="small"
              onClick={() => {
                setModalOpen(!modalOpen);
              }}
            />
          </div>
        </div>
      </BackgroundImage>

      {modalOpen && (
        <ModalVideo isOpen={modalOpen} link={link} onClose={() => setModalOpen(false)} />
      )}
    </div>
  );
});
export default SecondaryVideo;
