import { graphql, useStaticQuery } from 'gatsby';
const md = require('markdown-it')();
var mila = require('markdown-it-link-attributes');

const AlumniDatas = () => {
  md.use(mila, {
    attrs: {
      target: '_blank',
      rel: 'noopener',
    },
  });

  const data = useStaticQuery(graphql`
    query {
      paulineBouchereau: file(relativePath: { eq: "alumni/2022-01-alumni-pauline.jpeg" }) {
        childImageSharp {
          gatsbyImageData(quality: 90, width: 100, placeholder: NONE)
        }
      }
      johannPouponnot: file(relativePath: { eq: "alumni/2022-01-alumni-johann.jpg" }) {
        childImageSharp {
          gatsbyImageData(quality: 90, width: 100, placeholder: NONE)
        }
      }
      # manonBoiteau: file(relativePath: { eq: "alumni/2022-01-alumni-manon.jpg" }) {
      #   childImageSharp {
      # gatsbyImageData(quality: 90, width: 100, placeholder: NONE)
      #   }
      # }
      samySafsaf: file(relativePath: { eq: "alumni/2022-01-alumni-samy.png" }) {
        childImageSharp {
          gatsbyImageData(quality: 90, width: 100, placeholder: NONE)
        }
      }
      # priscilliaGoncalves: file(relativePath: { eq: "alumni/2022-01-alumni-priscillia.jpg" }) {
      #   childImageSharp {
      # gatsbyImageData(quality: 90, width: 100, placeholder: NONE)
      #   }
      # }
      cyrilLesot: file(relativePath: { eq: "alumni/2022-01-alumni-cyril.jpg" }) {
        childImageSharp {
          gatsbyImageData(quality: 90, width: 100, placeholder: NONE)
        }
      }
      quentinChapon: file(relativePath: { eq: "alumni/2022-01-alumni-quentin.jpg" }) {
        childImageSharp {
          gatsbyImageData(quality: 90, width: 100, placeholder: NONE)
        }
      }
      kevinMaslowski: file(relativePath: { eq: "alumni/2022-01-alumni-kevin.jpg" }) {
        childImageSharp {
          gatsbyImageData(quality: 90, width: 100, placeholder: NONE)
        }
      }
      mathieuBarisaux: file(relativePath: { eq: "alumni/2022-01-alumni-mathieu.jpg" }) {
        childImageSharp {
          gatsbyImageData(quality: 90, width: 100, placeholder: NONE)
        }
      }
      diegoChappedelaine: file(relativePath: { eq: "alumni/2022-01-alumni-diego.jpg" }) {
        childImageSharp {
          gatsbyImageData(quality: 90, width: 100, placeholder: NONE)
        }
      }
      julieDee: file(relativePath: { eq: "alumni/2022-01-alumni-julie.jpg" }) {
        childImageSharp {
          gatsbyImageData(quality: 90, width: 100, placeholder: NONE)
        }
      }
      # jimyMarletta: file(relativePath: { eq: "alumni/2022-01-alumni-jimy.jpeg" }) {
      #   childImageSharp {
      # gatsbyImageData(quality: 90, width: 100, placeholder: NONE)
      #   }
      # }
      louiseGrillon: file(relativePath: { eq: "alumni/2022-01-alumni-louise.jpg" }) {
        childImageSharp {
          gatsbyImageData(quality: 90, width: 100, placeholder: NONE)
        }
      }
      mihoubDebache: file(relativePath: { eq: "alumni/2022-01-alumni-mihoub.jpg" }) {
        childImageSharp {
          gatsbyImageData(quality: 90, width: 100, placeholder: NONE)
        }
      }
      thibaudVincent: file(relativePath: { eq: "alumni/2022-01-alumni-thibaud.jpg" }) {
        childImageSharp {
          gatsbyImageData(quality: 90, width: 100, placeholder: NONE)
        }
      }
      remiVinatier: file(relativePath: { eq: "alumni/2022-01-alumni-remi.jpg" }) {
        childImageSharp {
          gatsbyImageData(quality: 90, width: 100, placeholder: NONE)
        }
      }
      davidmargoses: file(relativePath: { eq: "alumni/david-margoses.jpg" }) {
        childImageSharp {
          gatsbyImageData(quality: 90, width: 100, placeholder: NONE)
        }
      }
      anthonysuzanne: file(relativePath: { eq: "alumni/anthony-suzanne.jpg" }) {
        childImageSharp {
          gatsbyImageData(quality: 90, width: 100, placeholder: NONE)
        }
      }
      juliendommel: file(relativePath: { eq: "alumni/julien-dommel.jpg" }) {
        childImageSharp {
          gatsbyImageData(quality: 90, width: 100, placeholder: NONE)
        }
      }
      bangtamnguyen: file(relativePath: { eq: "alumni/bangtam-nguyen.jpg" }) {
        childImageSharp {
          gatsbyImageData(quality: 90, width: 100, placeholder: NONE)
        }
      }

      marckalil: file(relativePath: { eq: "alumni/marc-kalil.jpg" }) {
        childImageSharp {
          gatsbyImageData(quality: 90, width: 100, placeholder: NONE)
        }
      }
      sarahejjilali: file(relativePath: { eq: "alumni/sarah-ejjilali.jpg" }) {
        childImageSharp {
          gatsbyImageData(quality: 90, width: 100, placeholder: NONE)
        }
      }
      thomasmorin: file(relativePath: { eq: "alumni/thomas-morin.jpg" }) {
        childImageSharp {
          gatsbyImageData(quality: 90, width: 100, placeholder: NONE)
        }
      }
      fatoudjitte: file(relativePath: { eq: "alumni/fatou-djitte.jpg" }) {
        childImageSharp {
          gatsbyImageData(quality: 90, width: 100, placeholder: NONE)
        }
      }
      julielesage: file(relativePath: { eq: "alumni/julie-lesage.jpg" }) {
        childImageSharp {
          gatsbyImageData(quality: 90, width: 100, placeholder: NONE)
        }
      }
      tovonandroyredobana: file(relativePath: { eq: "alumni/tovonandroy-redobana.jpg" }) {
        childImageSharp {
          gatsbyImageData(quality: 90, width: 100, placeholder: NONE)
        }
      }
      regisnuttin: file(relativePath: { eq: "alumni/regis-nuttin.jpg" }) {
        childImageSharp {
          gatsbyImageData(quality: 90, width: 100, placeholder: NONE)
        }
      }
      cedricpradels: file(relativePath: { eq: "alumni/cedric-pradels.jpg" }) {
        childImageSharp {
          gatsbyImageData(quality: 90, width: 100, placeholder: NONE)
        }
      }
      matthieupoupinet: file(relativePath: { eq: "alumni/matthieu-poupinet.jpg" }) {
        childImageSharp {
          gatsbyImageData(quality: 90, width: 100, placeholder: NONE)
        }
      }
      ludoviclamerre: file(relativePath: { eq: "alumni/ludovic-lamerre.jpg" }) {
        childImageSharp {
          gatsbyImageData(quality: 90, width: 100, placeholder: NONE)
        }
      }
      timotheebonnotte: file(relativePath: { eq: "alumni/timothee-bonnotte.jpg" }) {
        childImageSharp {
          gatsbyImageData(quality: 90, width: 100, placeholder: NONE)
        }
      }
      maximefremont: file(relativePath: { eq: "alumni/maxime-fremont.jpg" }) {
        childImageSharp {
          gatsbyImageData(quality: 90, width: 100, placeholder: NONE)
        }
      }
      jeanfrancoisgeorges: file(relativePath: { eq: "alumni/jean-francois-georges.jpg" }) {
        childImageSharp {
          gatsbyImageData(quality: 90, width: 100, placeholder: NONE)
        }
      }
      mariequittelier: file(relativePath: { eq: "alumni/marie-quittelier.jpg" }) {
        childImageSharp {
          gatsbyImageData(quality: 90, width: 100, placeholder: NONE)
        }
      }
      glorialuzio: file(relativePath: { eq: "alumni/gloria-luzio.jpg" }) {
        childImageSharp {
          gatsbyImageData(quality: 90, width: 100, placeholder: NONE)
        }
      }
      jeromevanseveren: file(relativePath: { eq: "alumni/jerome-vanseveren.jpg" }) {
        childImageSharp {
          gatsbyImageData(quality: 90, width: 100, placeholder: NONE)
        }
      }
      stephaniemerle: file(relativePath: { eq: "alumni/stephanie-merle.jpg" }) {
        childImageSharp {
          gatsbyImageData(quality: 90, width: 100, placeholder: NONE)
        }
      }
      ruddymariejoseph: file(relativePath: { eq: "alumni/ruddy-marie-joseph.jpg" }) {
        childImageSharp {
          gatsbyImageData(quality: 90, width: 100, placeholder: NONE)
        }
      }
      susanajacquin: file(relativePath: { eq: "alumni/susana-jacquin.jpg" }) {
        childImageSharp {
          gatsbyImageData(quality: 90, width: 100, placeholder: NONE)
        }
      }
      alexdisdier: file(relativePath: { eq: "alumni/alex-disdier.jpg" }) {
        childImageSharp {
          gatsbyImageData(quality: 90, width: 100, placeholder: NONE)
        }
      }
      # aliceollelaprune: file(relativePath: { eq: "alumni/alice-olle-laprune.jpg" }) {
      #   childImageSharp {        # gatsbyImageData(quality: 90, width: 100, placeholder: NONE)
      #   }
      # }
      thomasaksogut: file(relativePath: { eq: "alumni/thomas-aksogut.jpg" }) {
        childImageSharp {
          gatsbyImageData(quality: 90, width: 100, placeholder: NONE)
        }
      }
      chrishanduttoo: file(relativePath: { eq: "alumni/chrishan-duttoo.jpg" }) {
        childImageSharp {
          gatsbyImageData(quality: 90, width: 100, placeholder: NONE)
        }
      }
      benoithavet: file(relativePath: { eq: "alumni/benoit-havet.jpg" }) {
        childImageSharp {
          gatsbyImageData(quality: 90, width: 100, placeholder: NONE)
        }
      }
      victoriamartin: file(relativePath: { eq: "alumni/victoria-martin.jpg" }) {
        childImageSharp {
          gatsbyImageData(quality: 90, width: 100, placeholder: NONE)
        }
      }
      # shahbrankhan: file(relativePath: { eq: "alumni/shahbran-khan.jpg" }) {
      #   childImageSharp {
      # gatsbyImageData(quality: 90, width: 100, placeholder: NONE)
      #   }
      # }
      # arthurgoupil: file(relativePath: { eq: "alumni/arthur-goupil.jpg" }) {
      #   childImageSharp {
      # gatsbyImageData(quality: 90, width: 100, placeholder: NONE)
      #   }
      # }
      # kevinparayre: file(relativePath: { eq: "alumni/kevin-parayre.jpg" }) {
      #   childImageSharp {
      # gatsbyImageData(quality: 90, width: 100, placeholder: NONE)
      #   }
      # }
      vincentfagoaga: file(relativePath: { eq: "alumni/vincent-fagoaga.jpg" }) {
        childImageSharp {
          gatsbyImageData(quality: 90, width: 100, placeholder: NONE)
        }
      }
      alexandrebonzom: file(relativePath: { eq: "alumni/alexandre-bonzom.jpg" }) {
        childImageSharp {
          gatsbyImageData(quality: 90, width: 100, placeholder: NONE)
        }
      }
      clementlegal: file(relativePath: { eq: "alumni/clement-legal.jpg" }) {
        childImageSharp {
          gatsbyImageData(quality: 90, width: 100, placeholder: NONE)
        }
      }
      mathieubrochot: file(relativePath: { eq: "alumni/mathieu-brochot.jpg" }) {
        childImageSharp {
          gatsbyImageData(quality: 90, width: 100, placeholder: NONE)
        }
      }
      clementservillat: file(relativePath: { eq: "alumni/clement-servillat.jpg" }) {
        childImageSharp {
          gatsbyImageData(quality: 90, width: 100, placeholder: NONE)
        }
      }
      louislallier: file(relativePath: { eq: "alumni/louis-lallier.jpeg" }) {
        childImageSharp {
          gatsbyImageData(quality: 90, width: 100, placeholder: NONE)
        }
      }
      mariemenez: file(relativePath: { eq: "alumni/marie-menez.jpeg" }) {
        childImageSharp {
          gatsbyImageData(quality: 90, width: 100, placeholder: NONE)
        }
      }
    }
  `);

  const dataAlumni = [
    {
      name: 'Pauline',
      title: 'Full Stack Developer',
      avatar: data.paulineBouchereau.childImageSharp.gatsbyImageData,
      link: 'https://www.linkedin.com/in/pbouchereau/',
      blogpost: null,
      gender: 'female',
      company: 'Talent.io',
    },

    {
      name: 'Johann',
      title: 'Junior Software Engineer',
      avatar: data.johannPouponnot.childImageSharp.gatsbyImageData,
      link: 'https://www.linkedin.com/in/johann-pouponnot-618788173/',
      blogpost: null,
      gender: 'male',
      company: 'Acheel',
    },

    // {
    //   name: 'Manon',
    //   title: 'Frontend Developer',
    //   avatar: data.manonBoiteau.childImageSharp.gatsbyImageData,
    //   link: 'https://www.linkedin.com/in/manon-boiteau/',
    //   blogpost: null,
    //   gender: 'female',
    //   company: 'Likeo',
    // },

    {
      name: 'Samy',
      title: 'Développeur FullStack',
      avatar: data.samySafsaf.childImageSharp.gatsbyImageData,
      link: 'https://www.linkedin.com/in/samy-safsaf-39475320a/',
      blogpost: null,
      gender: 'male',
      company: 'SeDomicilier',
    },
    // {
    //   name: 'Priscillia',
    //   title: 'Développeuse web',
    //   avatar: data.priscilliaGoncalves.childImageSharp.gatsbyImageData,,
    //   link: 'https://www.linkedin.com/in/priscillia-goncalves-098285217/',
    //   blogpost: null,
    //   gender: 'female',
    //   company: 'Wever ',
    // },
    {
      name: 'Cyril',
      title: 'Développeur mobile',
      avatar: data.cyrilLesot.childImageSharp.gatsbyImageData,
      link: 'https://www.linkedin.com/in/cyril-lesot-384380100/',
      blogpost: null,
      gender: 'male',
      company: 'Perifit',
    },
    {
      name: 'Quentin',
      title: 'Frontend Developer',
      avatar: data.quentinChapon.childImageSharp.gatsbyImageData,
      link: 'https://www.linkedin.com/in/quentinchapon/',
      blogpost: null,
      gender: 'male',
      company: 'Globe Groupe',
    },

    {
      name: 'Kevin',
      title: 'Junior Software Engineer',
      avatar: data.kevinMaslowski.childImageSharp.gatsbyImageData,
      link: 'https://www.linkedin.com/in/kevin-maslowski-2130ab185/',
      blogpost: null,
      gender: 'male',
      company: 'Acheel',
    },
    {
      name: 'Mathieu',
      title: 'Développeur fullstack',
      avatar: data.mathieuBarisaux.childImageSharp.gatsbyImageData,
      link: 'https://www.linkedin.com/in/mathieu-barisaux/',
      blogpost: null,
      gender: 'male',
      company: 'Indépendant',
    },

    {
      name: 'Diego',
      title: 'Frontend Developer',
      avatar: data.diegoChappedelaine.childImageSharp.gatsbyImageData,
      link: 'https://www.linkedin.com/in/diegochappedelaine/',
      blogpost: null,
      gender: 'male',
      company: 'Publicis Sapient',
    },
    {
      name: 'Julie',
      title: 'Développeuse Fullstack',
      avatar: data.julieDee.childImageSharp.gatsbyImageData,
      link: 'https://www.linkedin.com/in/julie-dee/',
      blogpost: null,
      gender: 'female',
      company: 'Boost',
    },
    // {
    //   name: 'Jimy',
    //   title: 'Développeur web',
    //   avatar: data.jimyMarletta.childImageSharp.gatsbyImageData,
    //   link: 'https://www.linkedin.com/in/jimymarletta/',
    //   blogpost: null,
    //   gender: 'male',
    //   company: 'NeoDesign',
    // },
    {
      name: 'Louise',
      title: 'Développeuse Full-Stack JavaScript',
      avatar: data.louiseGrillon.childImageSharp.gatsbyImageData,
      link: 'https://www.linkedin.com/in/louise-g-a57773207/',
      blogpost: null,
      gender: 'female',
      company: 'Quizz Room',
    },
    {
      name: 'Mihoub',
      title: 'Développeur Web FullStack',
      avatar: data.mihoubDebache.childImageSharp.gatsbyImageData,
      link: 'https://www.linkedin.com/in/mihoub/',
      blogpost: null,
      gender: 'male',
      company: 'Mesri',
    },
    {
      name: 'Thibaud',
      title: 'Développeur ingénierie logiciel',
      avatar: data.thibaudVincent.childImageSharp.gatsbyImageData,
      link: 'https://www.linkedin.com/in/thibaudfaurevincent/',
      blogpost: null,
      gender: 'male',
      company: 'MadeCELL',
    },
    {
      name: 'Remi',
      title: 'Développeur front-end React Native',
      avatar: data.remiVinatier.childImageSharp.gatsbyImageData,
      link: 'https://www.linkedin.com/in/remi-vinatier/',
      blogpost: null,
      gender: 'male',
      company: 'Indépendant',
    },
    // {
    //   name: 'Arthur',
    //   title: 'Fullstack engineer',
    //   link: 'https://www.linkedin.com/in/arthur-goupil/',
    //   avatar: data.arthurgoupil.childImageSharp.gatsbyImageData,
    //   blogpost: null,
    //   gender: 'male',
    //   company: 'BlaBlaCar',
    //   logo: 'https://media-exp1.licdn.com/dms/image/C4D0BAQEXaZgckr3f8Q/company-logo_200_200/0/1599729758503?e=1621468800&v=beta&t=_56X05h28RaM-PGhli0wbUzQTWlEmsrsTkc0ICRoBp0',
    // },
    {
      name: 'David',
      title: 'Développeur front-end React Native',
      avatar: data.davidmargoses.childImageSharp.gatsbyImageData,
      link: 'https://www.linkedin.com/in/david-margoses-30722149/',
      blogpost: null,
      gender: 'male',
      company: 'Selency',
      logo: 'https://media-exp1.licdn.com/dms/image/C4E0BAQHkUGl2hxEFMA/company-logo_200_200/0/1579711941119?e=1621468800&v=beta&t=h46J-WvtJCDa8GLAose-CXyZyVWqGhNA-aa67OAB2KM',
    },
    {
      name: 'Anthony',
      title: 'Développeur Web',
      avatar: data.anthonysuzanne.childImageSharp.gatsbyImageData,
      link: 'https://www.linkedin.com/in/anthony-suzanne-3b3870199/?originalSubdomain=fr',
      blogpost: null,
      gender: 'male',
      company: 'Wethenew',
      logo: 'https://media-exp1.licdn.com/dms/image/C560BAQEQvZidYIkxbA/company-logo_200_200/0/1527256357595?e=1621468800&v=beta&t=ZNCfujX63DiytMx6qRzq5DENHE8nDrj3Cw4DkS8QJwg',
    },
    {
      name: 'Julien',
      title: 'Développeur front-end React Native',
      avatar: data.juliendommel.childImageSharp.gatsbyImageData,
      link: 'https://www.linkedin.com/in/julien-dommel-75b0b726/',
      blogpost: null,
      gender: 'male',
      company: 'Ekwateur',
      logo: 'https://media-exp1.licdn.com/dms/image/C4D0BAQGwOIQ3AbMvHw/company-logo_200_200/0/1605696530970?e=1621468800&v=beta&t=sBfRMVF_ixs_4SuYd0TyrPKgXA5ZBtMwELHt6JTKuqc',
    },
    {
      name: 'Bang-Tam',
      title: 'Développeuse Full-Stack',
      avatar: data.bangtamnguyen.childImageSharp.gatsbyImageData,
      link: 'https://www.linkedin.com/in/bangtamnguyen/',
      blogpost: null,
      gender: 'female',
      company: 'Fujitsu',
      logo: 'https://media-exp1.licdn.com/dms/image/C560BAQENN-_uQarUgQ/company-logo_100_100/0/1555020210482?e=1622073600&v=beta&t=-yw07JUYtbOU4DCF0H0M3nEldqXQ6Cd1IMwvkFSwiZk',
    },
    {
      name: 'Marc',
      title: 'Développeur React Native',
      avatar: data.marckalil.childImageSharp.gatsbyImageData,
      link: 'https://www.linkedin.com/in/marckalil/',
      blogpost: null,
      gender: 'male',
    },
    {
      name: 'Sarah',
      title: 'Développeuse Full-Stack',
      link: 'https://www.linkedin.com/in/sarah-ejjilali/',
      avatar: data.sarahejjilali.childImageSharp.gatsbyImageData,
      blogpost: null,
      gender: 'female',
      company: 'XXII',
      logo: 'https://media-exp1.licdn.com/dms/image/C560BAQF1REDX0UzXqw/company-logo_200_200/0/1547454592225?e=1621468800&v=beta&t=pFqsdhO7qtzM5qBhdWne45t1V5MqYhdFsnPFO8Wb5T4',
    },
    {
      name: 'Thomas',
      title: 'Développeur front-end',
      link: 'https://www.linkedin.com/in/thomas-morin-7aa830b4/',
      avatar: data.thomasmorin.childImageSharp.gatsbyImageData,
      blogpost: null,
      gender: 'male',
      company: 'AP-HP',
      logo: 'https://media-exp1.licdn.com/dms/image/C4D0BAQFC3D8JoKcM1w/company-logo_200_200/0/1519919743424?e=1621468800&v=beta&t=RzEIHwxapM1j3QMwEg8WFRkvORvcR10s0ORVgYrWDxA',
    },
    {
      name: 'Fatou',
      title: 'Développeuse Web front-end',
      link: 'https://www.linkedin.com/in/fatoudj/',
      avatar: data.fatoudjitte.childImageSharp.gatsbyImageData,
      blogpost: null,
      gender: 'female',
      company: 'Play Suisse',
      logo: 'https://media-exp1.licdn.com/dms/image/C4E0BAQFqIRkO8GXP_w/company-logo_200_200/0/1519900626158?e=1621468800&v=beta&t=cbKuNEIQzpMWttixB-Jcu3viL0znJ5lvRZiuzm2byFM',
    },
    {
      name: 'Julie',
      title: 'Développeuse Full-Stack React Native',
      link: 'https://www.linkedin.com/in/julie-lesage-92a09117/',
      avatar: data.julielesage.childImageSharp.gatsbyImageData,
      blogpost: null,
      gender: 'female',
      company: 'Uprate',
      logo: 'https://media-exp1.licdn.com/dms/image/C4D0BAQHS9Mx4XuagVQ/company-logo_200_200/0/1589361889647?e=1621468800&v=beta&t=67FmJ25MtyblOGdSPWejGdLv4bZHEtiP2YcDs12UssM',
    },
    {
      name: 'Tovonandroy',
      title: 'Développeur Full-Stack React Native',
      link: 'https://www.linkedin.com/in/tovonandroy/',
      avatar: data.tovonandroyredobana.childImageSharp.gatsbyImageData,
      blogpost: null,
      gender: 'male',
      company: 'Holy Owly',
    },
    {
      name: 'Régis',
      title: 'Développeur Full-Stack React JS',
      link: 'https://www.linkedin.com/in/r%C3%A9gis-nuttin-b51b22128/',
      avatar: data.regisnuttin.childImageSharp.gatsbyImageData,
      blogpost: null,
      gender: 'male',
      company: 'MadeCELL',
      logo: 'https://media-exp1.licdn.com/dms/image/C4D0BAQFLg9mfEibWTg/company-logo_200_200/0/1528902589520?e=1621468800&v=beta&t=2alqnxwND04N5dtU-dM9Cc6W3Cc-3Z0QFvoDCAcSLno',
    },
    {
      name: 'Cédric',
      title: 'Développeur front-end React JS',
      link: 'https://www.linkedin.com/in/c%C3%A9dric-pradels-32247172/',
      avatar: data.cedricpradels.childImageSharp.gatsbyImageData,
      blogpost: null,
      gender: 'male',
      company: 'Se Loger',
      logo: 'https://media-exp1.licdn.com/dms/image/C4D0BAQElyEePl-_0LQ/company-logo_200_200/0/1570957530259?e=1621468800&v=beta&t=beTPn9jbHi1lgPZ3IstSHslPcVXoXKoOzvuAuG5_b_s',
    },
    {
      name: 'Matthieu',
      title: 'Développeur front-end React JS',
      link: 'https://www.linkedin.com/in/matthieu-poupinet/',
      avatar: data.matthieupoupinet.childImageSharp.gatsbyImageData,
      blogpost: null,
      gender: 'male',
      company: 'Hello Golf',
      logo: 'https://media-exp1.licdn.com/dms/image/C4D0BAQGarFfy9Qznmw/company-logo_200_200/0/1605255938512?e=1621468800&v=beta&t=1Oz05dqZOHrIMw7r8wGFjnKF0HexnnzCC0db65esC5Y',
    },
    {
      name: 'Ludovic',
      title: 'Développeur Full-Stack Web & Mobile',
      link: 'https://www.linkedin.com/in/ludovic-lamerre-bbb62555/',
      avatar: data.ludoviclamerre.childImageSharp.gatsbyImageData,
      blogpost: null,
      gender: 'male',
      company: 'Tale',
    },
    {
      name: 'Timothée',
      title: 'Développeur React / React Native',
      link: 'https://www.linkedin.com/in/timoth%C3%A9e-bonnotte/',
      avatar: data.timotheebonnotte.childImageSharp.gatsbyImageData,
      blogpost: null,
      gender: 'male',
      company: 'SnapShift',
      logo: 'https://media-exp1.licdn.com/dms/image/C560BAQExjwt_Iw2GyQ/company-logo_200_200/0/1582814687547?e=1621468800&v=beta&t=ig-R-JWV2oq3DabFvuzNdu_JnpNqcew9z5R08dM1Z94',
    },
    {
      name: 'Maxime',
      title: 'Développeur Node.js',
      link: 'https://www.linkedin.com/in/maximefremont/',
      avatar: data.maximefremont.childImageSharp.gatsbyImageData,
      blogpost: null,
      gender: 'male',
      company: 'Phantombuster',
      logo: 'https://res-5.cloudinary.com/crunchbase-production/image/upload/c_lpad,h_170,w_170,f_auto,b_white,q_auto:eco/jomsygmw24gklzt9y2my',
    },
    {
      name: 'Jean-François',
      title: 'Développeur Full-Stack Web & Mobile',
      link: 'https://www.linkedin.com/in/jean-fran%C3%A7ois-georges-24254b13/',
      avatar: data.jeanfrancoisgeorges.childImageSharp.gatsbyImageData,
      blogpost: null,
      gender: 'male',
      company: 'Carbometrix',
    },
    {
      name: 'Marie',
      title: 'Développeuse Full-Stack JS',
      link: 'https://www.linkedin.com/in/marie-quittelier-933a0361/',
      avatar: data.mariequittelier.childImageSharp.gatsbyImageData,
      blogpost: null,
      gender: 'female',
    },
    {
      name: 'Gloria',
      title: 'Développeuse front-end React JS',
      link: 'https://www.linkedin.com/in/gloria-luzio-a7b05819b/',
      avatar: data.glorialuzio.childImageSharp.gatsbyImageData,
      blogpost: null,
      gender: 'female',
      company: 'Leboncoin ',
      logo: 'https://media-exp1.licdn.com/dms/image/C4D0BAQEQ8Tx2czPd2A/company-logo_200_200/0/1611599771177?e=1621468800&v=beta&t=iRZLminlMebyueBcLMmbPjJYTB5gvMP0roIyGDD-7zw',
    },
    {
      name: 'Jérôme',
      title: 'Développeur Web front-end',
      link: 'https://www.linkedin.com/in/jerome-vanseveren-67232760/',
      avatar: data.jeromevanseveren.childImageSharp.gatsbyImageData,
      blogpost: null,
      gender: 'male',
      company: 'MeilleurTaux',
      logo: 'https://media-exp1.licdn.com/dms/image/C4D0BAQFoObKxUl2QoQ/company-logo_200_200/0/1579878748716?e=1621468800&v=beta&t=Vyh9vKvUYsaKoRulbOqPRLLNqF5Q7kwKtGTI4FDj0Js',
    },
    {
      name: 'Stéphanie',
      title: 'Développeuse Full-Stack',
      link: 'https://www.linkedin.com/in/st%C3%A9phanie-merle-175a54134/',
      avatar: data.stephaniemerle.childImageSharp.gatsbyImageData,
      blogpost: null,
      gender: 'female',
      company: 'StructureFlow',
      logo: 'https://media-exp1.licdn.com/dms/image/C560BAQGBbCk5DRvNuw/company-logo_200_200/0/1519897287864?e=1621468800&v=beta&t=E-43RQKYqmz5v-Mf3kExXKOchUcl-IwxhPAkA7lg_eA',
    },
    {
      name: 'Ruddy',
      title: 'Développeur Full-Stack Web & Mobile',
      link: 'https://www.linkedin.com/in/ruddy-marie-joseph-709665b5/',
      avatar: data.ruddymariejoseph.childImageSharp.gatsbyImageData,
      blogpost: null,
      gender: 'male',
      company: 'DiGEiZ',
      logo: 'https://media-exp1.licdn.com/dms/image/C560BAQHyzGcw9r3mqA/company-logo_200_200/0/1519882630400?e=1621468800&v=beta&t=sjAVLL6LQeiJUs6_cpmu0b0fyWFA3e1I9zU5xAb2CLs',
    },
    {
      name: 'Susana',
      title: 'JavaScript Engineer',
      link: 'https://www.linkedin.com/in/susanajacquin/',
      avatar: data.susanajacquin.childImageSharp.gatsbyImageData,
      blogpost: null,
      gender: 'female',
      company: 'Botify',
      logo: 'https://media-exp1.licdn.com/dms/image/C4D0BAQHgwUch43UKKA/company-logo_200_200/0/1579822106102?e=1621468800&v=beta&t=7-EYB3NL3hW8h-CmGQ3_zyXSf8YX_kVo4RipMdYAy84',
    },
    {
      name: 'Alex',
      title: 'Développeur front-end',
      link: 'https://www.linkedin.com/in/alexdisdier/',
      avatar: data.alexdisdier.childImageSharp.gatsbyImageData,
      blogpost: null,
      gender: 'male',
      company: 'Trainline',
      logo: 'https://media-exp1.licdn.com/dms/image/C510BAQFozJG-NxuFFQ/company-logo_200_200/0/1519900589384?e=1621468800&v=beta&t=kKrKzcYWLnilr7iXYopWif3xs-IoDqdxk_5AYZtjv5Y',
    },
    // {
    //   name: 'Alice',
    //   title: 'Développeuse Full-Stack Web & Mobile',
    //   link: 'https://www.linkedin.com/in/alice-oll%C3%A9-laprune-b6535ba0/',
    //   avatar: data.aliceollelaprune.childImageSharp.gatsbyImageData,
    //   blogpost: null,
    //   gender: 'female',
    //   company: 'Sephora',
    //   logo: 'https://media-exp1.licdn.com/dms/image/C560BAQHpyqp2h6TAig/company-logo_200_200/0/1542829040598?e=1621468800&v=beta&t=HvvOg3dW7T32C3czj2lIyh3VmByHjN6YnaYr4Mi2HXw',
    // },
    {
      name: 'Thomas',
      title: 'Ingénieur Software',
      link: 'https://www.linkedin.com/in/thomas-aksogut/',
      avatar: data.thomasaksogut.childImageSharp.gatsbyImageData,
      blogpost: null,
      gender: 'male',
      company: 'AKS Digital Hub',
      logo: 'https://media-exp1.licdn.com/dms/image/C4D0BAQF-bRQK-6oQ1Q/company-logo_200_200/0/1569590665737?e=1621468800&v=beta&t=1mT9ilnlrTXX_VmIoeMckMwZvKQ2agjh63kUIsUCmB0',
    },
    {
      name: 'Chrishan',
      title: 'Développeur Full-Stack',
      link: 'https://www.linkedin.com/in/chrishan-duttoo-052b37161/',
      avatar: data.chrishanduttoo.childImageSharp.gatsbyImageData,
      blogpost: null,
      gender: 'male',
      company: 'Havas Factory',
      logo: 'https://media-exp1.licdn.com/dms/image/C4D0BAQEyvTd0ktTzpA/company-logo_200_200/0/1608674052069?e=1621468800&v=beta&t=MMyyToFBqz0JxQO_lKDWaIxrTsSFkXuVyk500v1vQEU',
    },
    {
      name: 'Benoît',
      title: 'Développeur front-end React JS',
      link: 'https://www.linkedin.com/in/beno%C3%AEt-havet-708752154/',
      avatar: data.benoithavet.childImageSharp.gatsbyImageData,
      blogpost: null,
      gender: 'male',
      company: 'Razorfish',
      logo: 'https://media-exp1.licdn.com/dms/image/C560BAQE5FQgsJuYvag/company-logo_200_200/0/1538982291411?e=1621468800&v=beta&t=8RFnA9lYEPrn0ueXeQTu2LN7q-fzy7RytpfvncVIwb0',
    },
    {
      name: 'Victoria',
      title: 'Développeuse front-end React JS',
      link: 'https://www.linkedin.com/in/victoria-martin-5766131b/',
      avatar: data.victoriamartin.childImageSharp.gatsbyImageData,
      blogpost: null,
      gender: 'female',
      company: 'Mention',
      logo: 'https://media-exp1.licdn.com/dms/image/C4D0BAQF_th7U-baSRQ/company-logo_200_200/0/1568649251012?e=1621468800&v=beta&t=N2A6WATNw6z2T0EQj6SfyPYdtvvZIJ_Yt-e-FlwDEPk',
    },
    // {
    //   name: 'Shahbran',
    //   title: 'Développeur React Native / Node.js',
    //   link: 'https://www.linkedin.com/in/developpeurreactjsnativekhanghulam/',
    //   avatar: data.shahbrankhan.childImageSharp.gatsbyImageData,
    //   blogpost: null,
    //   gender: 'male',
    //   company: 'Hubside',
    //   logo: 'https://media-exp1.licdn.com/dms/image/C4D0BAQGsukpTjDSAZg/company-logo_200_200/0/1588808087084?e=1621468800&v=beta&t=FbGlLzepjgUabGP66mO8UwSXRvmF7wRC-hOREU3CuSE',
    // },

    // {
    //   name: 'Kevin',
    //   title: 'Développeur front-end',
    //   link: 'https://www.linkedin.com/in/kevin-parayre-915623184/',
    //   avatar: data.kevinparayre.childImageSharp.gatsbyImageData,
    //   blogpost: null,
    //   gender: 'male',
    //   company: 'Placeloop',
    //   logo: 'https://media-exp1.licdn.com/dms/image/C560BAQE6kBb0wXPNng/company-logo_200_200/0/1588327483710?e=1621468800&v=beta&t=Oxy3rPwMpHQ9He-L8-WdI53N5PrUFMWGRVzi7ln798U',
    // },
    {
      name: 'Vincent',
      title: 'Consultant technique',
      link: 'https://www.linkedin.com/in/vincentfagoaga/',
      avatar: data.vincentfagoaga.childImageSharp.gatsbyImageData,
      blogpost: null,
      gender: 'male',
      company: 'Dewemo',
      logo: 'https://media-exp1.licdn.com/dms/image/C4D0BAQFj-RGUjwMOEw/company-logo_200_200/0/1606147865908?e=1621468800&v=beta&t=wGC-chbJ_wxnOtUWBCdD8hTrxgnQ2Faxh7iplfeYk7g',
    },
    {
      name: 'Alexandre',
      title: 'Consultant technique',
      link: 'https://www.linkedin.com/in/alexandre-bonzom-1a954361/',
      avatar: data.alexandrebonzom.childImageSharp.gatsbyImageData,
      blogpost: null,
      gender: 'male',
      company: 'Botify',
      logo: 'https://media-exp1.licdn.com/dms/image/C4D0BAQHgwUch43UKKA/company-logo_200_200/0/1579822106102?e=1621468800&v=beta&t=7-EYB3NL3hW8h-CmGQ3_zyXSf8YX_kVo4RipMdYAy84',
    },
    {
      name: 'Clément',
      title: 'Full-stack Developer',
      link: 'https://www.linkedin.com/in/clementlegal/',
      avatar: data.clementlegal.childImageSharp.gatsbyImageData,
      blogpost: null,
      gender: 'male',
      company: 'BimBamJob',
    },
    {
      name: 'Mathieu',
      title: 'Software Engineer',
      link: 'https://www.linkedin.com/in/mathieu-brochot/',
      avatar: data.mathieubrochot.childImageSharp.gatsbyImageData,
      blogpost: null,
      gender: 'male',
      company: 'Acheel',
      logo: 'https://media-exp1.licdn.com/dms/image/C4E0BAQG-aLBtAlauKQ/company-logo_200_200/0/1610463402954?e=1621468800&v=beta&t=1Zy2HXvK_kqhK2cs2MSupiOZuXKc5mjXtGEA37jKdLY',
    },
    {
      name: 'Clément',
      title: 'Développeur Full-Stack JS',
      link: 'https://www.linkedin.com/in/cl%C3%A9ment-servillat-aa830a4a/',
      avatar: data.clementservillat.childImageSharp.gatsbyImageData,
      blogpost: null,
      gender: 'male',
      company: 'Alt QA',
    },
    {
      name: 'Louis',
      title: 'Développeur Full-Stack',
      link: 'https://www.linkedin.com/in/louis-lallier-1a219111b/',
      avatar: data.louislallier.childImageSharp.gatsbyImageData,
      blogpost: null,
      gender: 'male',
      company: 'Brain.',
    },
    {
      name: 'Marie',
      title: 'Développeuse Full-Stack',
      link: 'https://www.linkedin.com/in/marie-menez-91186244/',
      avatar: data.mariemenez.childImageSharp.gatsbyImageData,
      blogpost: null,
      gender: 'female',
      company: 'Bocoloco',
    },
  ];
  return dataAlumni;
};

export default AlumniDatas;
