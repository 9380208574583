import React, { useEffect, useState } from 'react';
import { Paragraph, SubtitleDescription } from '../Typography';
import colors from 'src/style/colors';
import { Smartphone, Team, Popular } from 'src/components/Icons/Home';
import { mediaQueries } from 'src/style/breakpoints';
import { useHover } from '../../utils/useHover';
import { useMediaQueries } from '../../utils/MediaQueries';
import Theme from '../Theme';

const renderIcon = (iconName, color = colors.purple) => {
  if (iconName === 'Smartphone') {
    return <Smartphone color={color} />;
  }
  if (iconName === 'Popular') {
    return <Popular color={color} />;
  }
  if (iconName === 'Team') {
    return <Team color={color} />;
  }
};

/**
 * @type React.FunctionComponent<{ firstLine: string, secondLine: string, text: string , iconName: string}>
 */

const KeyPoints = ({ firstLine, secondLine, text, iconName }) => {
  const [hoverRef, isHover] = useHover();
  const [canHover, setCanHover] = useState(true);

  const transitionProps = {
    transitionProperty: 'all',
    transitionDuration: '0.3s',
    transitionTimingFunction: 'ease-in-out',
  };

  const mqs = useMediaQueries();

  useEffect(() => {
    if (mqs.desktopUp || mqs.bigDesktopUp) {
      setCanHover(true);
    } else {
      setCanHover(false);
    }
  }, [mqs.desktopUp, mqs.bigDesktopUp]);

  return (
    <div
      ref={hoverRef}
      css={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 5,
        height: 400,
        position: 'relative',
        overflow: 'hidden',
        [mediaQueries.phoneOnly]: {
          '&:not(:first-of-type)': { marginTop: 40 },
        },
        [mediaQueries.tabletLandscapeUp]: {
          width: 800,
        },
        [mediaQueries.desktopUp]: {
          flex: 1,
          width: 'auto',
          height: 445,
          '&:not(:last-child)': {
            marginRight: 15,
          },
          ...transitionProps,
          ...(canHover && isHover
            ? {
                backgroundColor: colors.purple,
                color: colors.white,
              }
            : {}),
        },
        [mediaQueries.bigDesktopUp]: {
          '&:not(:last-child)': {
            marginRight: 20,
          },
        },
      }}
    >
      <div
        css={{
          display: 'flex',
          alignItems: 'flex-end',
          paddingBottom: 20,
          ...transitionProps,
          [mediaQueries.desktopUp]:
            canHover && isHover
              ? {
                  flex: 1,
                  color: colors.white,
                  transform: 'translateY(-55px)',
                }
              : {},
        }}
      >
        {renderIcon(iconName, canHover && isHover ? colors.white : colors.purple)}
      </div>

      <div
        css={{
          flexDirection: 'column',
          display: 'flex',
          justifyContent: 'flex-start',
          paddingBottom: 24,
          ...transitionProps,
          [mediaQueries.desktopUp]:
            canHover && isHover
              ? {
                  flex: 1,
                  paddingBottom: 0,
                  color: colors.white,
                  transform: 'translateY(-55px)',
                }
              : {},
        }}
      >
        <Theme theme={canHover && isHover ? 'primary' : 'light'}>
          <SubtitleDescription
            css={{
              fontWeight: 'bold',
              paddingTop: 20,
              paddingBottom: 2,
              textAlign: 'center',
              ...transitionProps,
            }}
          >
            {firstLine}
          </SubtitleDescription>
          <SubtitleDescription
            css={{ fontWeight: 'bold', padding: '0px', textAlign: 'center', ...transitionProps }}
          >
            {secondLine}
          </SubtitleDescription>
        </Theme>
      </div>

      <Paragraph
        css={{
          color: colors.purpleDark,
          textAlign: 'center',
          display: 'block',
          fontSize: 19,
          padding: '0px 40px 0px 40px',
          [mediaQueries.desktopUp]: {
            color: colors.white,
            position: 'absolute',
            right: 0,
            top: 270,
            left: 0,
            opacity: canHover && isHover ? 1 : 0,
            transitionProperty: 'all',
            transitionDuration: '0.3s',
            transitionTimingFunction: 'ease-in-out',
            fontSize: 17,
            lineHeight: '25px',
          },
        }}
      >
        {text}
      </Paragraph>
    </div>
  );
};

export default KeyPoints;
