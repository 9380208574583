import React from 'react';

// Gatsby Image
import { graphql, useStaticQuery } from 'gatsby';

// Components
import { Section } from '../Section';
import { KeyPointsStatic } from 'src/components/Home';

// Styles
import { mediaQueries } from 'src/style/breakpoints';
import { SectionTitle, Paragraph } from 'src/components/Typography';
import { SectionHeaderBlock } from 'src/components/Section/elements';
import colors from 'src/style/colors';

const BestBootcamp = React.memo(({ theme }) => {
  const data = useStaticQuery(graphql`
    query {
      googlereview: file(relativePath: { eq: "awards/googlereview.png" }) {
        childImageSharp {
          gatsbyImageData(quality: 50, width: 300, placeholder: NONE)
        }
      }
      coursereport: file(relativePath: { eq: "awards/coursereport.png" }) {
        childImageSharp {
          gatsbyImageData(quality: 50, width: 300, placeholder: NONE)
        }
      }
      switchup: file(relativePath: { eq: "awards/switchup.png" }) {
        childImageSharp {
          gatsbyImageData(quality: 50, width: 300, placeholder: NONE)
        }
      }
    }
  `);

  return (
    <Section theme={theme ? theme : 'light'}>
      <div
        css={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <SectionHeaderBlock>
          <SectionTitle isMobileCenter isCenter>
            Le <span css={{ color: colors.purple }}>meilleur</span> bootcamp <br />
            Web et Mobile
          </SectionTitle>
        </SectionHeaderBlock>
      </div>
      <div>
        <div
          css={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            paddingBottom: 40,
            [mediaQueries.tabletLandscapeUp]: {
              padding: '30px 0 55px 0',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'row',
            },
          }}
        >
          <KeyPointsStatic
            source="SwitchUp"
            imgData={data.switchup.childImageSharp.gatsbyImageData}
            rate={4.9}
          />
          <KeyPointsStatic
            source="Course Report"
            imgData={data.coursereport.childImageSharp.gatsbyImageData}
            rate={4.9}
          />
          <KeyPointsStatic
            source="Google"
            imgData={data.googlereview.childImageSharp.gatsbyImageData}
            rate={4.9}
          />
        </div>
        <Paragraph css={{ textAlign: 'center', fontSize: 12 }}>
          Les enquêtes anonymes envoyées à nos élèves (en formation initiale) enregistrent également
          une note globale de <strong>4,9/5</strong>, sur les 100 derniers retours récoltés au cours
          des 18 derniers mois.
        </Paragraph>
      </div>
    </Section>
  );
});

export default BestBootcamp;
