import React from 'react';
import { Description } from '../DescriptionBlock';
import { UppercaseWords } from '../Typography';
import { AnchorButton } from '../Button';

const CourseFormat = ({ format, title, subtitle, description, url }) => {
  return (
    <div>
      <UppercaseWords>{format}</UppercaseWords>
      <Description title={title} subtitle={subtitle} text={description} />
      <AnchorButton to={url}>En savoir plus</AnchorButton>
    </div>
  );
};
export default CourseFormat;
