// Styles
import { Paragraph, SectionTitle } from 'src/components/Typography';
import React, { useState, useMemo } from 'react';

import AlumniCard from './AlumniCard';
// Data
import AlumniDatas from 'src/data/AlumniDatas';
import { Button } from '../../Button';
//Components
import { Section } from '../../Section';
import { SectionHeaderBlock } from 'src/components/Section/elements';
import { useMediaQueries } from '../../../utils/MediaQueries';
//Utils
import { shuffleFunction } from 'src/utils/shuffleFunction';

const AlumniJobGrid = React.memo(() => {
  const [isExpanded, setIsExpanded] = useState(false);
  const mqs = useMediaQueries();
  const dataAlumni = AlumniDatas();
  const randomOrderDataAlumni = useMemo(() => shuffleFunction(dataAlumni), []);

  // maxVisibleCards must a multiple of 1,2,3 and 4 - therefore a multiple of 12
  const maxVisibleCards = dataAlumni.length - (dataAlumni.length % 12);

  // Phone and small screens - 1 or 2 per row
  let visibleCards = isExpanded ? maxVisibleCards : 6;
  // Medium screen - 3 per row
  if (mqs.tabletLandscapeUp === true) {
    visibleCards = isExpanded ? maxVisibleCards : 9;
  }
  // Large screen - 4 per row
  if (mqs.mediumDesktopUp === true) {
    visibleCards = isExpanded ? maxVisibleCards : 12;
  }

  return (
    <Section theme="secondary" id="students">
      <div
        css={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <SectionHeaderBlock>
          <SectionTitle>Ce que sont devenus nos élèves</SectionTitle>
          <Paragraph>
            En passant par Le Reacteur, nos élèves acquièrent des compétences recherchées par de
            nombreuses entreprises de la tech et du digital, qu’ils peuvent mettre en application
            lors d’un stage de fin de formation ou directement en CDI.
          </Paragraph>
        </SectionHeaderBlock>
      </div>
      <div
        css={{
          position: 'relative',
          flexWrap: 'wrap',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          [`@media screen and (min-width: 670px)`]: {
            flexDirection: 'row',
            justifyContent: 'center',
          },
        }}
      >
        {randomOrderDataAlumni.slice(0, visibleCards).map((alumni, index) => {
          return <AlumniCard data={alumni} key={alumni.link} />;
        })}
        {!isExpanded && (
          <div
            css={{
              position: 'absolute',
              bottom: 0,
              left: '-20px',
              right: '-20px',
              height: 130,
              background:
                'linear-gradient(0deg, rgba(250,250,252,5) 50%, rgba(250,250,252,.2) 100%)',
            }}
          ></div>
        )}
      </div>

      <div css={{ display: 'flex', justifyContent: 'center', paddingTop: 30, paddingBottom: 20 }}>
        <Button
          onClick={() => setIsExpanded(!isExpanded)}
          css={{
            textDecoration: 'none',
            color: '#FFF',
          }}
        >
          {isExpanded ? 'Voir moins' : `Voir plus d'élèves`}
        </Button>
      </div>
    </Section>
  );
});

export default AlumniJobGrid;
