import { AnchorButton } from 'src/components/Button';
import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';
import Tag from 'src/components/Tags/Tag';
import { TagContainer } from 'src/components/Tags/elements';
import { TitleDescription, TitleDescriptionNonBold } from '../Typography';
import { TwoColumns } from '../Section';
/* --- import Media Queries ----*/
import { mediaQueries } from 'src/style/breakpoints';
import sizes from 'src/style/sizes';
import { useMediaQueries } from '../../utils/MediaQueries';

const OnlineOnsiteBlock = React.memo(
  ({ tags, title, content, duration, link, image, imageDescription, even, isLast }) => {
    const mqs = useMediaQueries();

    const text = (
      <div>
        <TagContainer>
          <Tag home color="purpleDark" spacer>
            {tags[0]}
          </Tag>
          <Tag home color="yellow" spacer>
            {tags[1]}
          </Tag>
          <Tag type={tags[2]} home />
        </TagContainer>
        <TitleDescription>
          {title}
          <br></br>
        </TitleDescription>
        <TitleDescriptionNonBold>{duration}</TitleDescriptionNonBold>
        {content}
        <AnchorButton to={link}>En savoir plus</AnchorButton>
      </div>
    );

    const img = (
      <GatsbyImage
        image={image}
        alt={imageDescription}
        css={{
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          height: '100%',
          backgroundColor: '#FFFFFF',
          borderRadius: 5,
        }}
      />
    );

    return (
      <div
        css={
          !isLast && {
            marginBottom: 100,
          }
        }
      >
        {mqs.tabletLandscapeUp ? (
          <TwoColumns
            isCardMobile
            reverse
            sizeLeft={sizes.columnWidth.medium}
            left={even ? img : text}
            sizeRight={sizes.columnWidth.medium}
            right={even ? text : img}
          />
        ) : (
          <div
            css={{
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
              boxShadow: 'rgba(0, 0, 0, 0.07) 1px 2px 15px',
              borderRadius: 12,
              overflow: 'hidden',
              textAlign: 'center',
            }}
          >
            <GatsbyImage
              image={image}
              alt={imageDescription}
              css={{
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
                backgroundColor: '#FFFFFF',
                borderRadius: 5,
                height: 400,
                [mediaQueries.phoneOnly]: {
                  height: 250,
                },
              }}
            />
            <div css={{ margin: 30 }}>
              <TagContainer>
                <Tag home color="purpleDark" spacer>
                  {tags[0]}
                </Tag>
                <Tag home color="yellow" spacer>
                  {tags[1]}
                </Tag>
              </TagContainer>
              <TagContainer>
                <Tag type={tags[2]} home />
              </TagContainer>
              <TitleDescription css={{ marginTop: 10 }}>
                {title}
                <br />
                {duration}
              </TitleDescription>
              {content}
              <AnchorButton to={link}>En savoir plus</AnchorButton>
            </div>
          </div>
        )}
      </div>
    );
  }
);
export default OnlineOnsiteBlock;
