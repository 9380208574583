import { graphql, useStaticQuery } from 'gatsby';

const Students = () => {
  const data = useStaticQuery(graphql`
    query {
      # alexandre: file(relativePath: { eq: "testimonials/alexandre.jpg" }) {
      #   childImageSharp {
      #     fluid(quality: 50, maxWidth: 900) {
      #       ...GatsbyImageSharpFluid_withWebp
      #     }
      #   }
      # }
      # alice: file(relativePath: { eq: "testimonials/alice.jpg" }) {
      #   childImageSharp {
      #     fluid(quality: 50, maxWidth: 900) {
      #       ...GatsbyImageSharpFluid_withWebp
      #     }
      #   }
      # }
      # audrey: file(relativePath: { eq: "testimonials/audrey.jpg" }) {
      #   childImageSharp {
      #     fluid(quality: 50, maxWidth: 900) {
      #       ...GatsbyImageSharpFluid_withWebp
      #     }
      #   }
      # }

      # jonathan: file(relativePath: { eq: "testimonials/jonathan.jpg" }) {
      #   childImageSharp {
      #     fluid(quality: 50, maxWidth: 900) {
      #       ...GatsbyImageSharpFluid_withWebp
      #     }
      #   }
      # }
      # liliane: file(relativePath: { eq: "testimonials/liliane.jpg" }) {
      #   childImageSharp {
      #     fluid(quality: 50, maxWidth: 900) {
      #       ...GatsbyImageSharpFluid_withWebp
      #     }
      #   }
      # }
      # ludovic: file(relativePath: { eq: "testimonials/ludovic.jpg" }) {
      #   childImageSharp {
      #     fluid(quality: 50, maxWidth: 900) {
      #       ...GatsbyImageSharpFluid_withWebp
      #     }
      #   }
      # }
      mathieu: file(relativePath: { eq: "testimonials/Testimonial_2023_Mathieu2.png" }) {
        childImageSharp {
          fluid(quality: 50, maxWidth: 900) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      bangTam: file(relativePath: { eq: "testimonials/Testimonial_2023_BangTam2.png" }) {
        childImageSharp {
          fluid(quality: 50, maxWidth: 900) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      jeanRene: file(relativePath: { eq: "testimonials/Testimonial_2023_JeanRene2.png" }) {
        childImageSharp {
          fluid(quality: 50, maxWidth: 900) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      marie: file(relativePath: { eq: "testimonials/Testimonial_2023_Marie2.png" }) {
        childImageSharp {
          fluid(quality: 50, maxWidth: 900) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      pauline: file(relativePath: { eq: "testimonials/Testimonial_2023_Pauline2.png" }) {
        childImageSharp {
          fluid(quality: 50, maxWidth: 900) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);
  const dataStudents = [
    // {
    //   index: 0,
    //   title: 'Liliane',
    //   subtitle: 'Entrepreneuse',
    //   thumbnail: data.liliane.childImageSharp.fluid,
    //   promo: 'Eté 2018',
    //   previousJob: 'Business Analyst',
    //   text: "Titulaire d'un MBA, Liliane a été consécutivement chef de projets, chargée d'études marketing et responsable stratégie. À l'été 2018, en pleine réflexion professionnelle, elle intègre le bootcamp du Reacteur. Décidée à monter un projet qui nécessitera la création d'un site et d'une application Mobile, elle apprend la programmation. En 2019, elle lance le site Yooster, afin d'accompagner les étudiants étrangers qui arrivent à Paris : logement, compte en banque, assurance habitation, mutuelle, etc.",
    //   link: 'https://www.youtube.com/embed/uZyFVwc1AO4',
    //   alt: "Avis d'elèves sur le bootcamp Reacteur Paris",
    // },
    // {
    //   index: 1,
    //   title: 'Ludovic',
    //   subtitle: 'Développeur Fullstack',
    //   thumbnail: data.ludovic.childImageSharp.fluid,
    //   promo: 'Hiver 2018',
    //   previousJob: 'Ingénieur',
    //   text: "Ingénieur thermique et fluide, Ludovic avait pour mission de développer des outils en interne pour les clients de son entreprise, en utilisant le langage VBA. Après avoir fait le tour de la question et poussé par sa créativité, il décide de tout quitter pour apprendre un langage de programmation polyvalent : il se tournera vers le JavaScript. À la sortie du Reacteur, après avoir travaillé avec la startup Gudule pendant son projet de fin d'études, il monte son entreprise de développement Web et Mobile.",
    //   link: 'https://www.youtube.com/embed/n-3BS0tWq2o',
    //   alt: "Avis d'elèves sur le bootcamp Reacteur Paris",
    // },
    // {
    //   index: 2,
    //   title: 'Alice',
    //   subtitle: 'Développeuse Full-Stack',
    //   thumbnail: data.alice.childImageSharp.fluid,
    //   promo: 'Eté 2018',
    //   previousJob: 'Ingénieur',
    //   text: "Alice travaillait en Norvège, sur les plateformes pétrolières. Lassée des contraintes que son ancien métier lui imposait et suite à une remise en question professionnelle, il lui est suggéré d'apprendre la programmation. Curieuse de nature, elle se lance dans l'aventure du code et finira par y trouver son équilibre. À la sortie de sa formation, elle est allée travailler chez Foncia pendant 9 mois, en temps que développeuse backend. Elle est désormais développeuse Mobile chez Sephora.",
    //   link: 'https://www.youtube.com/embed/vCao4EKW8S4',
    //   alt: "Avis d'elèves sur le bootcamp Reacteur Paris",
    // },
    // {
    //   index: 3,
    //   title: 'Jonathan',
    //   subtitle: 'Développeur Front-End',
    //   thumbnail: data.jonathan.childImageSharp.fluid,
    //   promo: 'Automne 2018',
    //   previousJob: 'Chef de Projet',
    //   text: "Jonathan travaillait chez Chanel en tant que chef de projet. Désireux de réaliser des choses plus concrètes, sans la contrainte des process à rallonge propres aux grandes enseignes, il tout quitté. Débutant en code, il a donc intégré Le Reacteur pour 10 semaines. Créatif, sérieux et travailleur, nous lui avons ensuite proposé de devenir développeur au sein de notre entreprise. Il s'était notamment distingué lors de son projet de fin d'étude en développant l'appli Mobile Bicycly qui permettait aux particuliers de mettre leurs vélos à la location.",
    //   link: 'https://www.youtube.com/embed/KZtFCbQcZzk',
    //   alt: "Avis d'elèves sur le bootcamp Reacteur Paris",
    // },
    // {
    //   index: 4,
    //   title: 'Audrey',

    //   subtitle: 'Développeur Full-Stack',
    //   thumbnail: data.audrey.childImageSharp.fluid,
    //   promo: 'Automne 2018',

    //   previousJob: 'Conseillère financière',
    //   text: "Après des études en économie et communication, Audrey rejoint le monde la banque. Conseillère chez LCL puis à la Caisse d'Épargne, elle commence à découvrir la programmation, sur son temps libre, au travers des tutoriels en ligne. Son intérêt pour le code grandissant, ses amis lui suggèrent d'envisager de changer de métier. Un jour, elle décide de démissionner et de s'investir à 100% dans la programmation. Après être passée par Reacteur, elle part en stage pendant 5 mois, puis se met à son compte en tant que Développeuse Full-Stack JavaScript.",
    //   link: 'https://www.youtube.com/embed/O_698tukO8g',
    //   alt: "Avis d'elèves sur le bootcamp Reacteur Paris",
    // },
    // {
    //   index: 5,

    //   title: 'Alexandre',
    //   subtitle: 'Développeur Front-End',
    //   thumbnail: data.alexandre.childImageSharp.fluid,
    //   promo: 'Hiver 2018',
    //   previousJob: 'Producteur et metteur en scène',
    //   text: "Après être monté sur les planches et s'être investi sur de nombreux projets en tant que producteur et metteur en scène, Alex a souhaité se tourner vers un métier qui lui donnerait la possibilité de travailler depuis n'importe où. Il commence donc à apprendre la programmation PHP en intégrant une formation de 3 mois puis, après un temps, il décide à venir au Reacteur pour apprendre le JavaScript. Après la formation, il a trouvé rapidement un poste de développeur chez Stratumn, startup fintech qui utilise la blockchain et la cryptographie avancée.",
    //   link: 'https://www.youtube.com/embed/hZvQmfnLunk',
    //   alt: "Avis d'elèves sur le bootcamp Reacteur Paris",
    // },
    {
      title: 'Bang Tam',
      subtitle: 'Apprendre à coder en 10 semaines',
      thumbnail: data.bangTam.childImageSharp.fluid,
      link: 'https://www.youtube.com/embed/hBEvV6laYiY',
      alt: "Avis d'elèves sur le bootcamp Reacteur Paris",
    },
    {
      title: 'Mathieu',
      subtitle: 'Se spécialiser dans JavaScript pour se lancer en freelance',
      thumbnail: data.mathieu.childImageSharp.fluid,
      link: 'https://www.youtube.com/embed/0U7Kx59WZaA',
      alt: "Avis d'elèves sur le bootcamp Reacteur Paris",
    },
    {
      title: 'Pauline',
      subtitle: `Se reconvertir sans partir dans de longues études`,
      thumbnail: data.pauline.childImageSharp.fluid,
      link: 'https://www.youtube.com/embed/rQH43MQId4E',
      alt: "Avis d'elèves sur le bootcamp Reacteur Paris",
    },
    {
      title: 'Marie',
      subtitle: `Passer de la comm' à la programmation`,
      thumbnail: data.marie.childImageSharp.fluid,
      link: 'https://www.youtube.com/embed/_huLrSEtrRQ',
      alt: "Avis d'elèves sur le bootcamp Reacteur Paris",
    },
    {
      title: 'Jean René',
      subtitle: 'Maîtriser les technos les + recherchées',
      thumbnail: data.jeanRene.childImageSharp.fluid,
      link: 'https://www.youtube.com/embed/ym_7Yjoy9GA',
      alt: "Avis d'elèves sur le bootcamp Reacteur Paris",
    },
  ];
  return dataStudents;
};

export default Students;
