import React from 'react';
import SecondaryVideo from './SecondaryVideo';

const VideosTestimonials = React.memo(({ data }) => {
  return (
    <div
      css={{
        display: 'flex',
        justifyContent: 'center',
        gap: 20,
        Height: '100%',
        marginTop: '65px',
      }}
    >
      {/* LEFT COLUMN */}
      <section
        css={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          gap: 20,
        }}
      >
        <SecondaryVideo link={data[1].link} mainThumbnail={data[1].thumbnail} />
        <SecondaryVideo link={data[2].link} mainThumbnail={data[2].thumbnail} />
      </section>
      {/* RIGHT COLUMN */}
      <section
        css={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          gap: 20,
        }}
      >
        <SecondaryVideo link={data[3].link} mainThumbnail={data[3].thumbnail} />
        <SecondaryVideo link={data[4].link} mainThumbnail={data[4].thumbnail} />
      </section>
    </div>
  );
});
export default VideosTestimonials;
