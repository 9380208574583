import React from 'react';

// Components
import { Section } from '../Section';
import { SkillLogo } from 'src/components/Bootcamp/Schedule/Program/index';

// Datas
import SkillsLogos from 'src/data/ProgramLogoSkills';

// Styles
import { mediaQueries } from 'src/style/breakpoints';
import { SectionTitle, TitleDescription, Paragraph } from 'src/components/Typography';
import { SectionHeaderBlock } from 'src/components/Section/elements';
import colors from 'src/style/colors';

const StackTech = React.memo(({ theme }) => {
  const [skillHovered, setSkillHovered] = React.useState(null);

  return (
    <Section theme={theme ? theme : 'light'}>
      <div
        css={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <SectionHeaderBlock css={{ maxWidth: 970, marginBottom: 40 }}>
          <SectionTitle isMobileCenter isCenter>
            Les <span css={{ color: colors.purple }}>technos</span> enseignées
          </SectionTitle>
          <TitleDescription css={{ margin: '15px 0 20px', fontWeight: 'lighter' }}>
            Maîtrisez les langages les plus demandés sur le marché du travail
          </TitleDescription>
        </SectionHeaderBlock>
      </div>
      {/* Icons */}
      <div
        css={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-around',
          flexWrap: 'wrap',
          [mediaQueries.tabletLandscapeUp]: {
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
          },
          [mediaQueries.phoneOnly]: {
            marginBottom: 20,
          },
        }}
      >
        {SkillsLogos.map(({ name, description, svg, onHome }, index) => {
          if (onHome) {
            return (
              <div
                key={name}
                css={{
                  paddingRight: 20,
                  paddingLeft: 20,
                  marginBottom: '1em',
                  display: 'flex',
                  justifyContent: 'flex-end',
                  width: '100%',
                  maxWidth: 185,
                  maxHeight: 70,
                  margin: '60px 20px',
                  flexDirection: 'column',
                  alignItems: 'center',
                  [mediaQueries.phoneOnly]: {
                    maxWidth: 77,
                    margin: '35px 8px',
                    padding: 0,
                    '& svg': {
                      transform: 'scale(0.7)',
                    },
                    '& p': { fontSize: 14 },
                  },
                }}
              >
                <SkillLogo
                  handleMouseEnter={() => {
                    setSkillHovered(name);
                  }}
                  handleMouseLeave={() => {
                    setSkillHovered(null);
                  }}
                  isHover={skillHovered === name}
                  svg={svg}
                  name={name}
                  description={description}
                />
                <Paragraph
                  css={{
                    color: '#9d9d9d',
                    fontSize: 16,
                    [mediaQueries.phoneOnly]: {
                      fontSize: 16,
                    },
                  }}
                >
                  {name}
                </Paragraph>
              </div>
            );
          } else {
            return null;
          }
        }).filter((item) => item !== null)}
      </div>
    </Section>
  );
});

export default StackTech;
